import Axios, { AxiosError } from 'axios';

import { AppConstant } from 'common';
import { PlatformAppConfigUnParsed } from 'types';
import { getRuntimeEnv } from 'utils/environment';
import { getRegExpMatchString } from 'utils/getRegExpMatchString';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

export const HEADERS = {
    AUTHORIZATION: 'Authorization',
    X_TENANT_ID: 'x-tenant-id',
    X_TENANT_APP_ID: 'x-tenant-app-id',
    X_ORGANIZATION_ID: 'X-Organization-Id',
};

const authRequestInterceptor = (config: any) => {
    const token = storage.getToken();
    const selectedOrg = storage.getSelectedOrgId();
    const vbcData = storage.getVBCAppData();
    if (token) {
        config.headers.set(HEADERS.AUTHORIZATION, `Bearer ${token}`);
    }
    if (selectedOrg) {
        config.headers.set(HEADERS.X_ORGANIZATION_ID, selectedOrg);
    }
    if (vbcData?.appTenantId && !config.headers.get(HEADERS.X_TENANT_ID)) {
        config.headers.set(HEADERS.X_TENANT_ID, vbcData?.appTenantId);
    }
    if (vbcData?.appTenantId && !config.headers.get(HEADERS.X_TENANT_APP_ID)) {
        config.headers.set(HEADERS.X_TENANT_APP_ID, vbcData?.appId);
    }

    config.headers.set('Accept', 'application/json');
    return config;
};

const beEnv = getRuntimeEnv();

export const axios = Axios.create({
    baseURL: beEnv === 'local' ? 'http://localhost:8000' : AppConstant.getAPIDomain(beEnv),
});

export interface AxiosErrorCustom extends AxiosError<any> {
    toastDisplayed: boolean;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        // Handle TPA organization Config response - Convert stringified config to JSON
        if (
            getRegExpMatchString(AppConstant.API_ROUTES.TPA, true).test(
                response.config.url as string
            ) &&
            response.data instanceof Array
        ) {
            if (response.data.length) {
                return response.data?.map((tpaObj: { config: string }) => {
                    const tpaConfig = JSON.parse(tpaObj.config);
                    tpaObj.config = tpaConfig;
                    return tpaObj;
                });
            }
            return response.data;
        }

        // Handle synthetic VBC Config response - Convert stringified config to JSON
        if (
            getRegExpMatchString(AppConstant.API_ROUTES.TPA_SYNTHETIC_VBC, false).test(
                response.config.url as string
            ) &&
            response.data instanceof Array
        ) {
            if (response.data.length) {
                return response.data?.map((syntheticVbc: { config: string }) => {
                    const syntheticVbcConfig = JSON.parse(syntheticVbc.config);
                    syntheticVbc.config = syntheticVbcConfig;
                    return syntheticVbc;
                });
            }
            return response.data;
        }

        // Handle VBC App Config response - Convert stringified config to JSON
        if (
            getRegExpMatchString(AppConstant.API_ROUTES.TENANT_APP_CONFIG, false).test(
                response.config.url as string
            ) &&
            response.data?.config
        ) {
            return { ...response.data, config: JSON.parse(response.data.config) };
        }

        // Handle My Apps Config response - Convert stringified config to JSON
        if (
            getRegExpMatchString(AppConstant.API_ROUTES.TENANT_APP_CONFIG, false).test(
                response.config.url as string
            ) &&
            response.data instanceof Array &&
            response.data?.length
        ) {
            return response.data.map((dataObj: PlatformAppConfigUnParsed) => ({
                ...dataObj,
                config: dataObj.config ? JSON.parse(dataObj.config) : null,
            }));
        }
        return response.data;
    },
    (error: AxiosError<any>): Promise<AxiosErrorCustom> => {
        let toastDisplayed = false;
        if (error.response?.status === 404) {
            return Promise.reject({ ...error });
        }
        if (error.response?.status === 429) {
            showErrorToast('Rate limit exceeded');
            toastDisplayed = true;
        } else if (error.response?.status === 417) {
            toastDisplayed = false;
        } else if (typeof error?.response?.data?.detail === 'string') {
            showErrorToast(error.response.data.detail);
            toastDisplayed = true;
        } else if (
            error?.response?.data?.detail?.length &&
            typeof error?.response?.data?.detail === 'object'
        ) {
            error.response.data.detail.forEach((err: { msg: string }) => {
                showErrorToast(err.msg);
            });
            toastDisplayed = true;
        }
        // eslint-disable-next-line no-console
        console.log(error);
        return Promise.reject({ ...error, toastDisplayed });
    }
);
