import { AppConstant } from 'common';
import { ORGANIZATION_NAME_MIN_LENGTH } from 'components/OrganizationSearchInput';
import { ILocalize } from 'types';

export const validators = {
    getPhone: (t: ILocalize) => ({
        pattern: {
            value: AppConstant.PHONE_VALIDATION_REGEX,
            message: t.PHONE_ERROR_MESSAGE,
        },
    }),

    getEIN: (required: boolean, t: ILocalize) => ({
        maxLength: {
            value: 10,
            message: t.ENTER_VALID_EIN,
        },
        pattern: {
            value: AppConstant.EIN_VALIDATION_REGEX,
            message: t.ENTER_VALID_EIN,
        },
        required: required
            ? {
                  value: true,
                  message: t.EIN_MANDATORY,
              }
            : undefined,
    }),
    getEmail: (t: ILocalize) => ({
        validate: {
            emailValidator: (email: string) => {
                if (email && !AppConstant.EMAIL_VALIDATION_REGEX.test(email)) {
                    return t.ENTER_VALID_EMAIL;
                }
                return true;
            },
        },
    }),
    getOrgName: (t: ILocalize, minLen: number = ORGANIZATION_NAME_MIN_LENGTH) => ({
        required: {
            value: true,
            message: t.ORGANIZATION_NAME_REQUIRED,
        },
        minLength: {
            value: minLen,
            message: t.ORGANIZATION_NAME_MIN_LENGTH.replace(/__MIN__/, `${minLen}`),
        },
    }),
};
