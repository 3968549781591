import React, { useEffect, useMemo } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';

import Logo from 'Assets/images/COEBRA-Logo.svg';
import CoebraLogo from 'Assets/images/COEUS-Logo-Tag.svg';
import { BSButton, FormControl, SVGIcon } from 'components/Elements';
import { useRouteQuery, useTranslate } from 'hooks';
import { getCopyrightYear } from 'utils/date';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';
import { validators } from 'utils/validators';

import { getLoginRedirect } from '../api/loginRedirect';
import { LoginRedirect } from '../types';

const Login: React.FC = () => {
    const { t } = useTranslate();
    const queryParams = useRouteQuery();
    const invitationEmail: string = useMemo(
        () => queryParams.get('invitation_email') as string,
        [queryParams]
    );

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
    } = useForm();

    useEffect(() => {
        if (invitationEmail) {
            setValue('email', invitationEmail);
        }
    }, [invitationEmail]);

    const handleLogin = async (data: FieldValues) => {
        try {
            const redirectData: LoginRedirect = await getLoginRedirect(data.email);
            storage.setLoginRedirectData(redirectData);
            window.location.href = redirectData.auth_uri;
        } catch {
            showErrorToast(t.ERROR_GET_LOGIN_REDIRECT_URL);
        }
    };

    return (
        <div id="background_branding_container" className="background_branding_container">
            <div className="login-page">
                <div className="left-section">
                    <Image src={CoebraLogo} alt="COEUS Logo" className="logo" />
                    <div>
                        <h1 className="left-section-h1">{t.LOGIN_HEADING}</h1>
                        <p className="left-section-p">{t.LOGIN_SUBHEADING}</p>
                        <BSButton
                            className="learn-more-button"
                            href="https://coebra.ai"
                            target="_blank"
                            rel="noreferrer"
                            type="button"
                        >
                            {t.LEARN_MORE}
                            <SVGIcon alt="Right arrow" icon="ArrowRightIcon" className="" />
                        </BSButton>
                    </div>
                    <div className="copy-right">
                        <p>
                            © <span id="copyrightYear">{getCopyrightYear()}</span>{' '}
                            {t.COPYRIGHT_TEXT}
                        </p>
                        <div className="cta-links">
                            <a href="https://coebra.ai/privacy" target="_blank" rel="noreferrer">
                                {t.PRIVACY_POLICY}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="company-website">
                    <img
                        data-tenant-branding-logo="true"
                        src="https://sachcoebradevpublic.blob.core.windows.net/public/computer.svg"
                        alt="Company Website"
                    />
                </div>
                <div className="right-section">
                    <div className="login-container">
                        <Image src={Logo} alt="COEBRA Logo" className="login-logo" />
                        <form className="login-form" onSubmit={handleSubmit(handleLogin)}>
                            <Row>
                                <Col sm="12" className="mb-3">
                                    <FormControl
                                        type="text"
                                        setValueFn={setValue}
                                        control={control}
                                        controlId="email"
                                        placeholder="Email Address"
                                        rules={{
                                            ...validators.getEmail(t),
                                            required: t.INVITE_EMAIL_REQUIRED_USER,
                                        }}
                                        isInvalid={!!errors.email}
                                        invalidFeedback={errors.email?.message as string}
                                    />
                                </Col>
                                <Col sm="12" className="d-flex justify-content-center">
                                    <BSButton
                                        type="submit"
                                        className="sign-in-button"
                                        disabled={isSubmitting}
                                    >
                                        {t.CONTINUE}
                                    </BSButton>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
