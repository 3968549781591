import * as React from 'react';
import { Row, Col, Form, Image } from 'react-bootstrap';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Logo from 'Assets/images/COEBRA-Logo.svg';
import CoebraLogo from 'Assets/images/COEUS-Logo-Tag.svg';
import { BSButton, FormControl, SVGIcon } from 'components/Elements';
import { UserInfo } from 'features/auth/types';
import { useTranslate } from 'hooks';
import { getCopyrightYear } from 'utils/date';
import storage from 'utils/storage';
import { validators } from 'utils/validators';

interface RegisterUserProps {
    setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | undefined>>;
}

const RegisterUser: React.FunctionComponent<RegisterUserProps> = ({ setUserInfo }) => {
    const { t } = useTranslate();
    const orgName = storage.getOrgName();

    const {
        setValue,
        handleSubmit,
        control,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'all',
    });

    const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
        setUserInfo(data as UserInfo);
    };

    return (
        <div id="background_branding_container" className="background_branding_container">
            <div className="login-page">
                <div className="left-section">
                    <Image src={CoebraLogo} alt="COEUS Logo" className="logo" />
                    <div>
                        <h1 className="left-section-h1">{t.LOGIN_HEADING}</h1>
                        <p className="left-section-p">{t.LOGIN_SUBHEADING}</p>
                        <BSButton
                            className="learn-more-button"
                            href="https://coebra.ai"
                            target="_blank"
                            rel="noreferrer"
                            type="button"
                        >
                            {t.LEARN_MORE}
                            <SVGIcon alt="Right arrow" icon="ArrowRightIcon" className="" />
                        </BSButton>
                    </div>
                    <div className="copy-right">
                        <p>
                            © <span id="copyrightYear">{getCopyrightYear()}</span>{' '}
                            {t.COPYRIGHT_TEXT}
                        </p>
                        <div className="cta-links">
                            <a href="https://coebra.ai/privacy" target="_blank" rel="noreferrer">
                                {t.PRIVACY_POLICY}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="company-website">
                    <img
                        data-tenant-branding-logo="true"
                        src="https://sachcoebradevpublic.blob.core.windows.net/public/computer.svg"
                        alt="Company Website"
                    />
                </div>
                <div className="right-section">
                    <div className="login-container">
                        <Image src={Logo} alt="COEBRA Logo" className="login-logo" />
                        <Form id="registerUser" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                            <div className="offcanvas-content">
                                <Row>
                                    <Col sm="12">
                                        <FormControl
                                            control={control}
                                            controlId="firstName"
                                            label={t.FIRST_NAME}
                                            isInvalid={!!errors.firstName}
                                            invalidFeedback={errors.firstName?.message as string}
                                            placeholder={t.FIRST_NAME}
                                            setValueFn={setValue}
                                            rules={{ required: t.FIRST_NAME_REQUIRED }}
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <FormControl
                                            control={control}
                                            controlId="lastName"
                                            label={t.LAST_NAME}
                                            isInvalid={!!errors.lastName}
                                            invalidFeedback={errors.lastName?.message as string}
                                            placeholder={t.LAST_NAME}
                                            setValueFn={setValue}
                                            rules={{ required: t.LAST_NAME_REQUIRED }}
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <FormControl
                                            className="mb-3"
                                            control={control}
                                            controlId="phone"
                                            label={t.PHONE}
                                            placeholder={t.PHONE_FORMAT}
                                            rules={validators.getPhone(t)}
                                            isInvalid={!!errors.phone}
                                            invalidFeedback={errors.phone?.message as string}
                                            setValueFn={setValue}
                                        />
                                        <FormControl
                                            className="mb-3"
                                            control={control}
                                            controlId="orgName"
                                            label={t.ORGANIZATION_NAME}
                                            isInvalid={!!errors.orgName}
                                            invalidFeedback={errors.orgName?.message as string}
                                            setValueFn={setValue}
                                            disabled={true}
                                            defaultValue={orgName}
                                        />
                                    </Col>
                                </Row>
                                <BSButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >
                                    {t.CONTINUE}
                                </BSButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterUser;
