import debounce from 'debounce';
import { FC, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Control, FieldValues, FieldErrors, UseFormSetValue } from 'react-hook-form';

import { getOrganizations } from 'common/api';
import { FormControl } from 'components/Elements';
import { useTranslate } from 'hooks';
import { Organization } from 'types';
import { validators } from 'utils/validators';

interface OrganizationSearchInputProps {
    control: Control<FieldValues, any>;
    errors: FieldErrors<FieldValues>;
    defaultValue?: string;
    typehead?: boolean;
    onOrgSelect?: (selectedOrg: Organization | null) => void;
    setValueFn: UseFormSetValue<FieldValues>;
    label?: string;
}

export const ORGANIZATION_NAME_MIN_LENGTH = 3;

export const OrganizationSearchInput: FC<OrganizationSearchInputProps> = ({
    control,
    errors,
    defaultValue = '',
    typehead = false,
    onOrgSelect,
    setValueFn,
    label,
}) => {
    const { t } = useTranslate();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
    const [orgsLoading, setOrgsLoading] = useState(false);
    const getOrgInputConditionalProps = () => {
        if (typehead) {
            return {
                onChange: debounce(async (value: string) => {
                    setSelectedOrg(null);
                    onOrgSelect && onOrgSelect(null);
                    setOrganizations([]);
                    if (value && value.trim().length >= ORGANIZATION_NAME_MIN_LENGTH) {
                        setOrgsLoading(true);
                        const orgs = await getOrganizations(value);
                        setOrganizations(orgs);
                        setOrgsLoading(false);
                    }
                }, 500),
                loading: orgsLoading,
                isValid: !!selectedOrg,
                validFeedback: t.ORGANIZATION_ALREADY_REGISTERED,
            };
        }
        return {};
    };

    return (
        <>
            <Dropdown className="auto-complete mb-3">
                <Dropdown.Toggle as="div">
                    <FormControl
                        className="w-100"
                        control={control}
                        controlId="orgName"
                        label={label || t.ORGANIZATION_NAME}
                        rules={validators.getOrgName(t, ORGANIZATION_NAME_MIN_LENGTH)}
                        isInvalid={!!errors.orgName}
                        invalidFeedback={errors.orgName?.message as string}
                        defaultValue={defaultValue}
                        setValueFn={setValueFn}
                        {...getOrgInputConditionalProps()}
                    />
                </Dropdown.Toggle>
                {typehead && organizations.length > 0 && (
                    <Dropdown.Menu className="w-100">
                        {organizations.map((org: Organization) => (
                            <Dropdown.Item
                                onClick={() => {
                                    setSelectedOrg(org);
                                    onOrgSelect && onOrgSelect(org);
                                }}
                                key={org.id}
                            >
                                {org.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                )}
            </Dropdown>
        </>
    );
};
