import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { AppConstant } from 'common';
import { ErrorFallback } from 'components/ErrorFallback';
import RegisterUser from 'features/verifyInvites/components/RegisterUser';
import { useTranslate } from 'hooks';
import { dispatch } from 'store';
import { decodeToken } from 'store/authSlice';
import { showFullscreenLoader, hideFullscreenLoader } from 'store/loaderSlice';
import { setPostLoginBanner } from 'store/toastSlice';
import storage from 'utils/storage';

import { getAccessToken } from '../api/accessToken';
import { useAccessTokenPayload } from '../hooks';
import { AccessToken, UserInfo } from '../types';

const AuthResponse: React.FC = () => {
    const { t } = useTranslate();
    const { payload, isChangePassword } = useAccessTokenPayload();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [showRegisterUserForm, setShowRegisterUserForm] = useState(false);
    const [refreshToken, setRefreshToken] = useState<string>('');
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const orgName = storage.getOrgName();

    const handleGetAccessToken = async () => {
        try {
            dispatch(showFullscreenLoader());
            const accessToken: AccessToken = await getAccessToken({
                ...payload,
                ...(userInfo
                    ? {
                          user_info: {
                              first_name: userInfo.firstName,
                              last_name: userInfo.lastName,
                              organization_name: userInfo.orgName,
                              phone: userInfo.phone,
                          },
                      }
                    : {}),
                ...(refreshToken && { refresh_token: refreshToken }),
            });
            storage.setLogoutHint(accessToken.logout_hint);
            dispatch(decodeToken(accessToken.access_token));
            dispatch(setPostLoginBanner(t.POST_LOGIN_BANNER));
            navigate(AppConstant.ROUTE_PATHS.PLATFORM);
        } catch (error) {
            if ((error as any).response?.status === 417 && orgName) {
                setRefreshToken((error as any).response.data.detail);
                setShowRegisterUserForm(true);
            } else {
                dispatch(decodeToken(null));
                setError(true);
            }
        } finally {
            dispatch(hideFullscreenLoader());
        }
    };

    useEffect(() => {
        if (!showRegisterUserForm) {
            if (isChangePassword) {
                navigate(AppConstant.ROUTE_PATHS.PLATFORM);
            } else {
                handleGetAccessToken();
            }
        }
        if (userInfo) {
            handleGetAccessToken();
        }
    }, [payload, isChangePassword, userInfo]);

    if (showRegisterUserForm) {
        return <RegisterUser setUserInfo={setUserInfo} />;
    } else return <>{error && <ErrorFallback />}</>;
};

export default AuthResponse;
